.page-top .top-mv {
  background: url("/assets/img/top/mv_image_sp.jpg") no-repeat center bottom/cover;
  height: 465rem;
  padding-top: 48rem;
}
@media (min-width: 768px) {
  .page-top .top-mv {
    height: calc(100vh - var(--heightHeaderPc));
    max-height: 700rem;
    min-height: 560rem;
    background: url("/assets/img/top/mv_image_pc.jpg") no-repeat top right 50%/cover;
  }
}
.page-top .top-mv__inner {
  height: 100%;
}
@media (min-width: 768px) {
  .page-top .top-mv__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 80rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-mv__text-area {
    background: rgba(255, 255, 255, 0.5);
    width: 440rem;
    height: 440rem;
    margin-right: 0;
    margin-left: auto;
    padding-right: 56rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.page-top .top-mv__lead {
  text-align: center;
  color: var(--colorMain1);
  font-family: var(--fontEn2);
  font-weight: normal;
  letter-spacing: 0.05em;
}
@media (max-width: 767px) {
  .page-top .top-mv__lead {
    font-size: 27rem;
    line-height: 1.09;
  }
}
@media (min-width: 768px) {
  .page-top .top-mv__lead {
    text-align: right;
    font-size: 55rem;
    line-height: 1.272;
  }
}
.page-top .top-mv__title {
  text-align: center;
  font-weight: 500;
  color: var(--colorText2);
  font-family: var(--fontMincho1);
  font-size: 17rem;
  margin-top: 16rem;
}
@media (min-width: 768px) {
  .page-top .top-mv__title {
    text-align: right;
    font-size: 27rem;
    line-height: 1.5;
    margin-top: 50rem;
  }
}
.page-top .top-mv__title__sub {
  display: block;
  font-size: 12rem;
  letter-spacing: 0.06em;
}
@media (min-width: 768px) {
  .page-top .top-mv__title__sub {
    font-size: 18rem;
    margin-bottom: 4rem;
  }
}
.page-top .top-about {
  padding-top: 50rem;
  padding-bottom: 50rem;
}
@media (min-width: 768px) {
  .page-top .top-about {
    padding-top: 120rem;
    padding-bottom: 40rem;
  }
}
.page-top .top-about__inner {
  position: relative;
}
@media (min-width: 768px) {
  .page-top .top-about__inner {
    padding-top: 35%;
  }
}
.page-top .top-about__image {
  overflow: hidden;
  border-radius: 13rem;
}
@media (min-width: 768px) {
  .page-top .top-about__image {
    border-radius: 18rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    width: 100%;
  }
}
.page-top .top-about__text-area {
  padding: 22rem 10rem 0;
}
@media (min-width: 768px) {
  .page-top .top-about__text-area {
    width: calc(50% + 90rem);
    padding: 80rem 8.5% 80rem;
    background: var(--colorBg1);
    position: relative;
    z-index: 2;
    border-radius: 0 20rem 20rem 0;
  }
  .page-top .top-about__text-area .m-section-heading__main {
    letter-spacing: 0.07em;
  }
}
.page-top .top-about__text {
  margin-top: 10rem;
}
@media (min-width: 768px) {
  .page-top .top-about__text {
    margin-top: 18rem;
  }
}
.page-top .top-about__button {
  margin-top: 20rem;
  text-align: center;
}
@media (min-width: 768px) {
  .page-top .top-about__button {
    text-align: right;
    margin-top: 18rem;
  }
}
.page-top .top-business {
  background: var(--colorBg2);
  position: relative;
  z-index: 2;
}
.page-top .top-business__inner {
  padding-top: 45rem;
  padding-bottom: 50rem;
}
@media (min-width: 768px) {
  .page-top .top-business__inner {
    padding-left: calc(50% + 116rem);
    margin-top: 75rem;
    position: relative;
    padding-top: 68rem;
    padding-bottom: 70rem;
  }
}
.page-top .top-business__panel {
  display: flex;
  justify-content: space-between;
  margin-top: 25rem;
}
@media (min-width: 768px) {
  .page-top .top-business__panel {
    max-width: calc(50% + 46rem);
    width: 580rem;
    height: 100%;
    position: absolute;
    left: 0;
    top: -75rem;
    margin-top: 0;
  }
}
.page-top .top-business__panel__block {
  background: no-repeat bottom left/cover;
  width: calc((100% - 24rem) / 3);
  height: 212rem;
  border-radius: 8rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  transition: 0.6s;
}
@media (min-width: 768px) {
  .page-top .top-business__panel__block {
    border-radius: 16rem;
    width: calc((100% - 40rem) / 3);
    height: calc(100% + 54rem);
  }
}
.page-top .top-business__panel__block--01 {
  background-image: url("/assets/img/top/business_panel1_sp.jpg");
  margin-top: 13rem;
}
@media (min-width: 768px) {
  .page-top .top-business__panel__block--01 {
    background-image: url("/assets/img/top/business_panel1_pc.jpg");
    margin-top: 30rem;
  }
}
.page-top .top-business__panel__block--02 {
  margin-top: 34rem;
  background-image: url("/assets/img/top/business_panel2_sp.jpg");
  transition-delay: 0.2s;
}
@media (min-width: 768px) {
  .page-top .top-business__panel__block--02 {
    background-image: url("/assets/img/top/business_panel2_pc.jpg");
    margin-top: 90rem;
  }
}
.page-top .top-business__panel__block--03 {
  background-image: url("/assets/img/top/business_panel3_sp.jpg");
  transition-delay: 0.4s;
}
@media (min-width: 768px) {
  .page-top .top-business__panel__block--03 {
    background-image: url("/assets/img/top/business_panel3_pc.jpg");
  }
}
.page-top .top-business.js-view-observer:not(.is-active) .top-business__panel__block {
  opacity: 0;
  transform: translateY(10rem);
}
.page-top .top-business__panel__block__text {
  color: #fff;
  font-size: 20rem;
  font-weight: 600;
  letter-spacing: 0.2em;
  display: flex;
  align-items: center;
  padding-right: 6rem;
}
@media (min-width: 768px) {
  .page-top .top-business__panel__block__text {
    font-size: 35rem;
    line-height: 1;
    padding-bottom: 10rem;
    padding-right: 12rem;
  }
}
.page-top .top-business__description {
  margin-top: 25rem;
}
.page-top .top-business__description + .top-business__description {
  margin-top: 10rem;
}
.page-top .top-business__description strong {
  font-weight: 500;
}
.page-top .top-business__button {
  margin-top: 20rem;
  text-align: center;
}
@media (min-width: 768px) {
  .page-top .top-business__button {
    text-align: right;
    margin-top: 20rem;
  }
}
.page-top .top-blog {
  padding-top: 36rem;
  padding-bottom: 50rem;
  position: relative;
}
@media (min-width: 768px) {
  .page-top .top-blog {
    padding-top: 175rem;
    padding-bottom: 110rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-blog__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .page-top .top-blog__list {
    width: 261rem;
    margin: 15rem auto 0;
  }
}
@media (min-width: 768px) {
  .page-top .top-blog__list {
    width: 100%;
    order: 3;
    margin-top: 15rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-blog__list__panel {
    width: calc((100% - 40rem) / 3);
    margin-right: 20rem;
  }
  .page-top .top-blog__list__panel:last-child {
    margin-right: 0;
  }
}
.page-top .top-blog__list__panel .m-article-panel__title {
  margin-top: -2rem;
}
.page-top .top-blog__arrow {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 356rem;
  left: 50%;
  transform: translateX(-50%);
  top: 170rem;
  z-index: 2;
}
@media (min-width: 768px) {
  .page-top .top-blog__arrow {
    display: none;
  }
}
.page-top .top-blog__arrow__button {
  width: 32rem;
  height: 32rem;
  background: url("/assets/img/common/icon_circle_arrow.svg") no-repeat center center/contain;
  white-space: nowrap;
  text-indent: 100%;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
}
.page-top .top-blog__arrow__button--prev {
  transform: scaleX(-1);
}
.page-top .top-blog__arrow__button.swiper-button-disabled {
  pointer-events: none;
  opacity: 0.4;
}
@media (max-width: 767px) {
  .page-top .top-blog__button {
    text-align: center;
    margin-top: 18rem;
  }
}
.page-top .top-news {
  background: var(--colorBg2);
  padding-top: 36rem;
  padding-bottom: 50rem;
}
@media (min-width: 768px) {
  .page-top .top-news {
    padding-top: 90rem;
    padding-bottom: 120rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-news__inner {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .page-top .top-news__list {
    margin-top: 15rem;
    margin-right: 10rem;
    margin-left: 10rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-news__list {
    margin-top: 22rem;
    width: calc(50% + 190rem);
  }
}
.page-top .top-movie {
  padding-top: 40rem;
  padding-bottom: 50rem;
}
@media (min-width: 768px) {
  .page-top .top-movie {
    padding-top: 100rem;
    padding-bottom: 120rem;
  }
}
@media (max-width: 767px) {
  .page-top .top-movie .m-inner {
    padding-left: var(--sidePaddingSp2);
    padding-right: var(--sidePaddingSp2);
  }
}
@media (min-width: 768px) {
  .page-top .top-movie .m-inner {
    max-width: calc(1120px + var(--sidePaddingPc1) * 2);
  }
}
.page-top .top-movie__content {
  width: 100%;
  padding-top: 56.6%;
  position: relative;
  overflow: hidden;
  margin-top: 20rem;
}
@media (min-width: 768px) {
  .page-top .top-movie__content {
    margin-top: 20rem;
  }
}
.page-top .top-movie__content iframe {
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
}
.page-top .top-instagram {
  background: var(--colorBg3);
  padding: 40rem 0 50rem;
}
@media (min-width: 768px) {
  .page-top .top-instagram {
    padding: 100rem 0 120rem;
  }
}
.page-top .top-instagram__heading {
  text-align: center;
  margin-bottom: 18rem;
}
@media (min-width: 768px) {
  .page-top .top-instagram__heading {
    margin-bottom: 38rem;
  }
}
.page-top .top-instagram__heading .m-extra-heading__main {
  font-size: 25rem;
}
@media (min-width: 768px) {
  .page-top .top-instagram__heading .m-extra-heading__main {
    font-size: 50rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-instagram__heading .m-extra-heading__sub {
    font-size: 24rem;
  }
}
@media (max-width: 767px) {
  .page-top .top-instagram__list {
    width: 290rem;
    margin-right: auto;
    margin-left: auto;
  }
}
.page-top .top-instagram__list #sb_instagram {
  padding: 0 !important;
}
.page-top .top-instagram__list #sbi_images {
  display: flex;
  padding: 0 !important;
}
@media (max-width: 767px) {
  .page-top .top-instagram__list #sbi_images {
    flex-wrap: wrap;
    gap: 10rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-instagram__list #sbi_images {
    gap: 20rem;
  }
}
.page-top .top-instagram__list .sbi_item {
  width: 100%;
}
@media (max-width: 767px) {
  .page-top .top-instagram__list .sbi_item {
    width: calc((100% - 10rem) / 2);
  }
}
.page-top .top-instagram__list .sbi_lightbox_carousel_icon {
  display: none;
}
.page-top .top-instagram__list .sbi_photo {
  background: none !important;
  position: relative;
  display: block;
  overflow: hidden;
}
.page-top .top-instagram__list .sbi_photo img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}
@media (min-width: 768px) {
  .page-top .top-instagram__list .sbi_photo:hover img {
    transform: scale(1.05, 1.05);
  }
}
.page-top .top-instagram__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260rem;
  height: 35rem;
  border: 1px solid var(--colorText2);
  margin: 25rem auto 0;
  color: var(--colorText2);
  font-family: var(--fontEn1);
  font-size: 15rem;
  padding-bottom: 2rem;
}
@media (min-width: 768px) {
  .page-top .top-instagram__button {
    margin-top: 50rem;
    width: 360rem;
    height: 50rem;
    transition: 0.3s;
    font-size: 23rem;
  }
  .page-top .top-instagram__button:hover {
    border-color: var(--colorMain2);
    background: var(--colorMain2);
    color: #fff;
  }
}
.page-top .top-instagram__button::before {
  content: "";
  display: block;
  width: 20rem;
  height: 20rem;
  background: url("/assets/img/common/icon_instagram.svg") no-repeat center center/contain;
  margin-right: 6rem;
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .page-top .top-instagram__button::before {
    margin-right: 8rem;
  }
}
@media (min-width: 768px) {
  .page-top .top-instagram__button:hover::before {
    background-image: url("/assets/img/common/icon_instagram_white.svg");
  }
}