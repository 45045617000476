.page-top {

  .top-mv {
    background: url("/assets/img/top/mv_image_sp.jpg") no-repeat center bottom / cover;
    height: 465rem;
    padding-top: 48rem;
    @include wide {
      height: calc(100vh - var(--heightHeaderPc));
      max-height: 700rem;
      min-height: 560rem;
      background: url("/assets/img/top/mv_image_pc.jpg") no-repeat top right 50% / cover;
    }
  }
  .top-mv__inner {
    height: 100%;
    @include wide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 80rem;
    }
  }
  .top-mv__text-area {
    @include wide {
      background: rgba(#FFF,0.5);
      width: 440rem;
      height: 440rem;
      margin-right: 0;
      margin-left: auto;
      padding-right:56rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
  .top-mv__lead {
    text-align: center;
    color:var(--colorMain1);
    font-family: var(--fontEn2);
    font-weight: normal;
    letter-spacing: 0.05em;
    @include sp {
      font-size: 27rem;
      line-height: 1.09;
    }
    @include wide {
      text-align: right;
      font-size: 55rem;
      line-height: 1.272;
    }
  }
  .top-mv__title {
    text-align: center;
    font-weight: 500;
    color:var(--colorText2);
    font-family: var(--fontMincho1);
    font-size: 17rem;
    margin-top: 16rem;
    @include wide {
      text-align: right;
      font-size: 27rem;
      line-height: 1.5;
      margin-top: 50rem;
    }
  }
  .top-mv__title__sub {
    display: block;
    font-size: 12rem;
    letter-spacing: 0.06em;
    @include wide {
      font-size: 18rem;
      margin-bottom: 4rem;
    }
  }
  
  .top-about {
    padding-top: 50rem;
    padding-bottom: 50rem;
    @include wide {
      padding-top: 120rem;
      padding-bottom: 40rem;
    }
  }
  .top-about__inner {
    position: relative;
    @include wide {
      padding-top: 35%;
    }
  }
  .top-about__image {
    overflow: hidden;
    border-radius: 13rem;
    @include wide {
      border-radius: 18rem;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      width:100%;
    }
  }
  .top-about__text-area {
    padding:22rem 10rem 0;
    @include wide {
      width:calc(50% + 90rem);
      padding: 80rem 8.5% 80rem;
      background: var(--colorBg1);
      position: relative;
      z-index: 2;
      border-radius:0 20rem 20rem 0;
      .m-section-heading__main {
        letter-spacing: 0.07em;
      }
    }
  }
  .top-about__text {
    margin-top: 10rem;
    @include wide {
      margin-top: 18rem;
    }
  }
  .top-about__button {
    margin-top: 20rem;
    text-align: center;
    @include wide {
      text-align: right;
      margin-top: 18rem;
    }
  }
  
  .top-business {
    background: var(--colorBg2);
    position: relative;
    z-index: 2;
  }
  .top-business__inner {
    padding-top: 45rem;
    padding-bottom: 50rem;
    @include wide {
      padding-left: calc(50% + 116rem);
      margin-top: 75rem;
      position: relative;
      padding-top: 68rem;
      padding-bottom: 70rem;
    }
  }
  .top-business__panel {
    display: flex;
    justify-content: space-between;
    margin-top: 25rem;
    @include wide {
      max-width: calc(50% + 46rem);
      width: 580rem;
      height: 100%;
      position: absolute;
      left: 0;
      top: -75rem;
      margin-top: 0;
    }
  }
  .top-business__panel__block {
    background:no-repeat bottom left / cover;
    width: calc((100% - 24rem) / 3);
    height: 212rem;
    border-radius: 8rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    transition: .6s;
    @include wide {
      border-radius: 16rem;
      width: calc((100% - 40rem) / 3);
      height: calc(100% + 54rem);
    }
    &--01 {
      background-image: url("/assets/img/top/business_panel1_sp.jpg");
      margin-top: 13rem;
      @include wide {
        background-image: url("/assets/img/top/business_panel1_pc.jpg");
        margin-top: 30rem;
      }
    }
    &--02 {
      margin-top: 34rem;  
      background-image: url("/assets/img/top/business_panel2_sp.jpg");
      transition-delay: .2s;
      @include wide {
        background-image: url("/assets/img/top/business_panel2_pc.jpg");
        margin-top: 90rem;
      }
    }
    &--03 {
      background-image: url("/assets/img/top/business_panel3_sp.jpg");
      transition-delay: .4s;
      @include wide {
        background-image: url("/assets/img/top/business_panel3_pc.jpg");
      }
    }
  }
  .top-business.js-view-observer:not(.is-active) {
    .top-business__panel__block {
      opacity: 0;
      transform: translateY(10rem);
    }
  }
  .top-business__panel__block__text {
    color: #fff;
    font-size: 20rem;
    font-weight: 600;
    letter-spacing: 0.2em;
    display: flex;
    align-items: center;
    padding-right: 6rem;
    @include wide {
      font-size: 35rem;
      line-height: 1;
      padding-bottom: 10rem;
      padding-right: 12rem;
    }
  }
  .top-business__description {
    margin-top: 25rem;
    &+.top-business__description {
      margin-top: 10rem;
    }
    strong {
      font-weight: 500;
    }
  }
  .top-business__button {
    margin-top: 20rem;
    text-align: center;
    @include wide {
      text-align: right;
      margin-top: 20rem;
    }
  }
  
  .top-blog {
    padding-top: 36rem;
    padding-bottom: 50rem;
    position: relative;
    @include wide {
      padding-top: 175rem;
      padding-bottom: 110rem;
    }
  }
  .top-blog__inner {
    @include wide {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  }
  .top-blog__list {
    @include sp {
      width: 261rem;
      margin:15rem auto 0;
    }
    @include wide {
      width: 100%;
      order:3;
      margin-top: 15rem;
    }
  }
  .top-blog__list__panel {
    @include wide {
      width: calc((100% - 40rem) / 3);
      margin-right: 20rem;
      &:last-child {
        margin-right: 0;
      }
    }
    .m-article-panel__title {
      margin-top: -2rem;
    }
  }
  .top-blog__arrow {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 356rem;
    left: 50%;
    transform: translateX(-50%);
    top: 170rem;
    z-index: 2;
    @include wide {
      display: none;
    }
  }
  .top-blog__arrow__button {
    width: 32rem;
    height: 32rem;
    background: url("/assets/img/common/icon_circle_arrow.svg") no-repeat center center / contain;
    white-space: nowrap;
    text-indent: 100%;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    &--prev {
      transform: scaleX(-1);
    }
    &.swiper-button-disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  .top-blog__button {
    @include sp {
      text-align: center;
      margin-top: 18rem;
    }
  }
  
  .top-news {
    background: var(--colorBg2);
    padding-top: 36rem;
    padding-bottom: 50rem;
    @include wide {
      padding-top: 90rem;
      padding-bottom: 120rem;
    }
  }
  .top-news__inner {
    @include wide {
      display: flex;
      justify-content: space-between;
    }
  }
  .top-news__list {
    @include sp {
      margin-top: 15rem;
      margin-right: 10rem;
      margin-left: 10rem;
    }
    @include wide {
      margin-top: 22rem;
      width: calc(50% + 190rem);
    }
  }
  
  .top-movie {
    padding-top: 40rem;
    padding-bottom: 50rem;
    @include wide {
      padding-top: 100rem;
      padding-bottom: 120rem;
    }
    .m-inner {
      @include sp {
        padding-left: var(--sidePaddingSp2);
        padding-right: var(--sidePaddingSp2);
      }
      @include wide {
        max-width: calc(1120px + var(--sidePaddingPc1) * 2);
      }
    }
  }
  .top-movie__content {
    width: 100%;
    padding-top: 56.6%;
    position: relative;
    overflow: hidden;
    margin-top: 20rem;
    @include wide {
      margin-top: 20rem;
    }
    iframe {
      position: absolute;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      left: -1px;
      top: -1px;
    }
  }

  .top-instagram {
    background: var(--colorBg3);
    padding:40rem 0 50rem;
    @include wide {
      padding:100rem 0 120rem;
    }
  }
  .top-instagram__heading {
    text-align: center;
    margin-bottom: 18rem;
    @include wide {
      margin-bottom: 38rem;
    }
    .m-extra-heading__main {
      font-size: 25rem;
      @include wide {
        font-size: 50rem;
      }
    }
    .m-extra-heading__sub {
      @include wide {
        font-size: 24rem;
      }
    }
  }
  .top-instagram__list {
    @include sp {
      width: 290rem;
      margin-right: auto;
      margin-left: auto;
    }
    #sb_instagram {
      padding: 0 !important;
    }
    #sbi_images {
      display: flex;
      padding: 0 !important;
      @include sp {
        flex-wrap: wrap;
        gap:10rem;
      }
      @include wide {
        gap:20rem;
      }
    }
    .sbi_item {
      width: 100%;
      @include sp {
        width: calc((100% - 10rem) / 2);
      }
    }
    .sbi_lightbox_carousel_icon {
      display: none;
    }
    .sbi_photo {
      background: none !important;
      position: relative;
      display: block;
      overflow: hidden;
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .3s;
      }
      &:hover {
        img {
          @include wide {
            transform: scale(1.05,1.05);
          }
        }
      }
    }
  }
  .top-instagram__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260rem;
    height: 35rem;
    border:1px solid var(--colorText2);
    margin: 25rem auto 0;
    color:var(--colorText2);
    font-family: var(--fontEn1);
    font-size: 15rem;
    padding-bottom: 2rem;
    @include wide {
      margin-top: 50rem;
      width: 360rem;
      height: 50rem;
      transition: .3s;
      font-size: 23rem;
      &:hover {
        border-color: var(--colorMain2);
        background:var(--colorMain2);
        color: #fff;
      }
    }
    &::before {
      content: '';
      display: block;
      width: 20rem;
      height: 20rem;
      background: url("/assets/img/common/icon_instagram.svg") no-repeat center center / contain;
      margin-right: 6rem;
      margin-top: 2rem;
      @include wide {
        margin-right: 8rem;
      }
    }
    &:hover {
      &::before {
        @include wide {
          background-image: url("/assets/img/common/icon_instagram_white.svg");
        }
      }
    }
  }

}